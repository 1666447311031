@import "./Animations.scss";
@import "./Buttons.scss";
@import "./Colors.scss";
@import "./FlexTools.scss";
@import "./Loaders.scss";
@import "./Navbar.scss";
@import "./Sections.scss";

@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";

@import "~react-responsive-carousel/lib/styles/carousel.css";

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

body {
    font-family: 'Roboto', sans-serif;
}