@import "./Colors.scss";

.loading-feedback {
    background-color: rgba(0,0,0,0.6);
    position: fixed;
    top:0;
    left: 0;
    z-index: 999999999 !important;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    h3 {
      animation-name: text-beating;
      animation-iteration-count: infinite;
      animation-duration: 1s;
      color: white;
    }

    @keyframes text-beating {
      0%{color: white;}
      50%{color: rgba(255,255,255, 0.5);}
      100%{color: white;}
    }
  }

  .circle-loader {
    position: fixed;
    bottom: 20px;
    right: 20px;

    .lds-ring {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
      }
      .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 8px solid $darkGrey;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: $darkGrey transparent transparent transparent;
      }
      .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
      }
      .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
      }
      .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
      }
      @keyframes lds-ring {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
          
        }
      }
    }



  .page-loading {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;


    .logo {
      width: 200px;
      animation-name: beat;
      animation-duration: 1s;
      animation-iteration-count: infinite;
    }

    @keyframes beat {
      
    0% {
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
    }
  }