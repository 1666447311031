@import "./Colors.scss";

.btn {
    outline:none !important;
    box-shadow: none !important;
}

.btn-min-width {
    min-width: 200px;
}

.custom-file {
    box-shadow: none !important;
    outline: none !important;

    &:focus {
        box-shadow: none !important;
        outline: none !important;
    }

    &:active {
        box-shadow: none !important;
        outline: none !important;
    }

    .custom-file-input {
        box-shadow: none !important;
        outline: none !important;

        &:focus {
            box-shadow: none !important;
            outline: none !important;
        }
    
        &:active {
            box-shadow: none !important;
            outline: none !important;
        }
    }
}

.btn-custom-1 {
    border-color: $secondaryColor;
    background-color: transparent;

    color:$secondaryColor;
    transition: 0.3s;

    &:hover {
        background-color: transparent;
        border-color: transparent;
        color:$secondaryColor;
    }
}


.btn-custom-2 {
    background-color: $secondaryColor;
    color: white;
    transition: 0.3s;

    &:hover {
        background-color: darken($color: $secondaryColor, $amount: 10);
        color: white;
    }
}

.btn-custom-3 {
    background-color:darken($color: $secondaryColor, $amount: 20);
    color: white;
    transition: 0.3s;

    &:hover {
        background-color: darken($color: $secondaryColor, $amount: 30);
        color: white;
    }
}

.form-control {
    box-shadow: none !important;
}


.form-control-1 {
    background-color: $lightGrey2;
    box-shadow: none !important;
    border-color: $lightGrey2;

    &:focus {
        border-color: $secondaryColor;
        background-color: $lightGrey2;
    }
}


.form-control-2 {
    box-shadow: none !important;
    &:focus {
     border-color: $darkGrey;
 }
}


.max-width-350 {
    max-width: 350px;
}


.custom-select {
   // background-color: $lightGrey;
   box-shadow: none !important;
   &:focus {
    border-color: $darkGrey;
}
}

@media screen and (max-width:800px){

    .btn-min-width {
        min-width: 80px;
    }
    
}