@import "./Colors.scss";


.close-now {
    animation-name: close-now !important;
    animation-duration: 0.4s !important;
    animation-iteration-count: 1 !important;
    
}

@keyframes close-now {

    0% {
        transform: translate(0,0);
    }
    100% {
        transform: translate(100%,0);
    }

}